import * as _vue4 from "vue";
var _vue3 = "default" in _vue4 ? _vue4.default : _vue4;
var exports = {};
exports.__esModule = true;
exports.isDefined = exports.isUndefined = exports.isFunction = undefined;
var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
  return typeof obj;
} : function (obj) {
  return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
};
exports.isString = isString;
exports.isObject = isObject;
exports.isHtmlElement = isHtmlElement;
var _vue = _vue3;
var _vue2 = _interopRequireDefault(_vue);
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
function isString(obj) {
  return Object.prototype.toString.call(obj) === "[object String]";
}
function isObject(obj) {
  return Object.prototype.toString.call(obj) === "[object Object]";
}
function isHtmlElement(node) {
  return node && node.nodeType === Node.ELEMENT_NODE;
}

/**
 *  - Inspired:
 *    https://github.com/jashkenas/underscore/blob/master/modules/isFunction.js
 */
var isFunction = function isFunction(functionToCheck) {
  var getType = {};
  return functionToCheck && getType.toString.call(functionToCheck) === "[object Function]";
};
if (typeof /./ !== "function" && (typeof Int8Array === "undefined" ? "undefined" : _typeof(Int8Array)) !== "object" && (_vue2.default.prototype.$isServer || typeof document.childNodes !== "function")) {
  exports.isFunction = isFunction = function isFunction(obj) {
    return typeof obj === "function" || false;
  };
}
exports.isFunction = isFunction;
var isUndefined = exports.isUndefined = function isUndefined(val) {
  return val === void 0;
};
var isDefined = exports.isDefined = function isDefined(val) {
  return val !== undefined && val !== null;
};
export default exports;
export const __esModule = exports.__esModule;
const _isDefined = exports.isDefined,
  _isUndefined = exports.isUndefined,
  _isFunction = exports.isFunction,
  _isString = exports.isString,
  _isObject = exports.isObject,
  _isHtmlElement = exports.isHtmlElement;
export { _isDefined as isDefined, _isUndefined as isUndefined, _isFunction as isFunction, _isString as isString, _isObject as isObject, _isHtmlElement as isHtmlElement };